function NarrativeTheology() {
  return (
    <>
      This is the Narrative Theology page.
      <h1>Narrative Theology</h1>
      <p>
        Narrative theology is a way of understanding the Bible as a story. This
        story is not just a story of the past, but a story that continues to
        unfold in the present. It is a story that invites us to participate in
        the ongoing work of God in the world.
      </p>
      <div>
        <a href="/narrative-theology">Definition of Narrative Theology</a>
      </div>
      <div>
        <a href="/narrative-theology">Problem of Divine Hiddeness</a>
      </div>
      <div>
        <a href="/narrative-theology">
          Scrutiny against the Philosophical Standard
        </a>
      </div>
    </>
  );
}

export default NarrativeTheology;
